<template>
  <div>
    <div
      class="my-chart"
      @click="handleClick(item)"
      v-for="item in chartList"
      :key="item.id"
    />
  </div>
</template>

<script>
import { onBeforeMount, inject, getCurrentInstance, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Home",

  setup(_, context) {
    let echarts = inject("ec");
    const router = useRouter();

    const { proxy } = getCurrentInstance();

    const userInfo = JSON.parse(localStorage.getItem("user_info"));
    const chartList = ref([]);

    const renderChart = () => {
      const myCharts = document.querySelectorAll(".my-chart");
      for (let i = 0; i < myCharts.length; i++) {
        let myChart = echarts.init(myCharts[i]);
        let current = chartList.value[i];

        // 绘制图表
        myChart.setOption({
          series: [
            {
              type: "gauge",
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 1,
              splitNumber: 20,
              title: {
                // offsetCenter: [0, "-120%"],
                // fontSize: "0.12rem",
                 offsetCenter: [0, "-120%"],
                fontSize: "0.12rem",
              },
              progress: {
                show: true,
                width: 16,
              },
              axisLine: {
                lineStyle: {
                  width: 22,
                },
              },
              pointer: {
                itemStyle: {
                  color: "#5370c6",
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                length: 15,
                lineStyle: {
                  color: "#999",
                  width: 2,
                },
              },
              axisLabel: {
                formatter: () => "",
              },
              detail: {
                fontSize: "0.3rem",
                offsetCenter: [0, "30%"],
                valueAnimation: true,
                formatter: function (value) {
                  return (value * 100).toFixed(1) + "%";
                },
                color: "#333",
              },
              data: [
                {
                  value:
                    Math.abs(current.temperature - current.highLevel) /
                    current.highLevel,
                  name: `${current.buildingId}号楼${current.unitId}单元${
                    current.roomId
                  }温度${
                    current.temperature < current.lowLevel
                      ? "过低"
                      : current.temperature > current.highLevel
                      ? "过高"
                      : "正常"
                  }`,
                },
              ],
            },
          ],
        });
      }
    };

    const handleClick = (val) => {
      router.push({
        name: "history",
        query: { type: "warn" },
        params: {
          info: JSON.stringify(val),
        },
      });
    };

    const getList = async () => {
      context.emit('changeLoading', true)
      let res = await proxy.axios.get(
        "/api/base/warning?communityId=" + userInfo[0].communityId
      );
      if (res.code == 200 && res.data.length) {
        chartList.value = res.data;
         context.emit('changeLoading', false)
        let timer = null;
        timer = setInterval(() => {
          if (document.querySelectorAll(".my-chart").length) {
            renderChart();
            clearInterval(timer);
          }
        }, 10);
      } else {
        getList();
      }
    };

    onBeforeMount(() => {
      getList();
    });

    return {
      handleClick,
      chartList,
    };
  },
};
</script>

<style scoped lang="scss">
.my-chart {
  margin: 0.2rem 0 0;
  border-bottom: 1px solid #ddd;
  width: 100%;
  height: 2.2rem;
}
</style>
